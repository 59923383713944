import { graphql, useStaticQuery } from "gatsby"
import React, { createContext } from "react"
import { useMachine } from "@xstate/react"

import { assign, setup } from "xstate"
type EventType = "next" | "submit" | "loaded"
type Event = {
  type: EventType
  value?: number
}

export type Question = {
  theme: string
  title: string
  text: string
  max: string
  min: string
  image: string
  question: string
  hero: any
  inverted: boolean
  parties: {party: string, value: number}[]
}

type Context = {
  answers: number[]
  questions: Queries.QuestionsJson[]
  opinion?: number
  parties?: { party: string; percentage: number; }[]
  index: number
}


type MachineContext = [{
  value: string,
  context: Context
},
(event: Event) => void]

export const questionMachine = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
    input: {} as {questions: Queries.QuestionsJson[]}
  },
}).createMachine({
  id: "questions",
  initial: "instructions",
  context: ({ input }) => ({
    answers: [],
    questions: input.questions,
    index: 0,
  }),
  states: {
    question: {
      entry:[ 
        ({context}) => {
          typeof window !== "undefined" && process.env.NODE_ENV ==="production" && window?.gtag("event", "question", context.index +1)
      }],
      on: {
        next: {
          target: "loading",
          actions: assign({
            index: ({ context }) => context.index + 1,
            answers: ({ context, event }) => [...context.answers, context.questions[context.index].inverted ?  5 - event.value : event.value], 
          }),
        },
      },
    },
    instructions: {
      on: {
        next: {
          target: "loading"
        }
      }
    },
    loading: {
      on: {
        loaded: [
          {
            target: "question",
            guard: ({ context }) =>
              context.answers.length < context.questions.length,
          },
          {
            target: "result",
          },
        ],
      },
    },
    result: {
      entry: [assign({
        opinion: ({context}) => percentageScore(context.answers,4,1),
      }),
      assign({
        parties: ({context}) => {
          const partyAnswers = context.questions?.reduce<{ [party: string]: number[] }>((scores, question) => {
            question.parties?.forEach(({ party, value }) => {
              if (!(party in scores)) {
                scores[party] = [value]
              } else scores[party] = [...scores[party], value]
            })
            return scores
          }, {})

          let parties = closestParties(context.answers, partyAnswers, 4)
          if (context.opinion < 30) {
            
            const max = Object.entries(parties).reduce((max, [_, score]) => score.percentage > max ? score.percentage : max, 0)
            const numOfMaxes = parties.reduce( (count, party) => party.percentage === max ? count+1: count,0)
            const AFS = parties.find((party) => party.party === "AFS")
            if (AFS && (AFS.percentage !== max || numOfMaxes > 1)) {
              const addon = Math.max(1, Math.round(Math.random() * 5)+1)
              AFS.percentage = Math.min(max + addon,100)
              parties = parties.sort((a, b) => a.percentage < b.percentage ? 1 : -1)
            }
          }
          return parties
        }
      }),
    ({context}) => {
    typeof window !== "undefined" && process.env.NODE_ENV ==="production" && window?.gtag("event", "finished test", { closestParties: context.parties[0].party, opinion: context.opinion})

  }  ]
    },
  },
})

export const StateContext = createContext<MachineContext >([{}, ()=>{}] as unknown as MachineContext)

export const Provider = ({ children }: React.PropsWithChildren) => {
  const data = useStaticQuery<Queries.FragorQuery>(graphql`
  query Fragor{
    allQuestionsJson {
      nodes {
        text
        theme
        title
        source
        link
        min
        question
        max
        image
        inverted
        parties {
          party
          value
        }
        hero {
          childImageSharp {
            gatsbyImageData(aspectRatio: 2)
          }
        }
      }
    }
  }
  `)

  const questions = (data.allQuestionsJson.nodes as Queries.QuestionsJson[])
    .sort(() => 0.5 - Math.random())
    .slice(0, process.env.NODE_ENV == "production" ? 8 : 3) as Queries.QuestionsJson[]



  return <StateContext.Provider value={useMachine(questionMachine, {
    input: {
      questions,
    }
  }) as unknown as MachineContext}>{children}</StateContext.Provider>
}

const percentageScore = (answers: number[], max: number, min: number) => {
  if (!answers) return 0
  const sum = answers.reduce((sum, score) => sum + score, 0)
  return Math.round(
    ((sum - min * answers.length) /
      (max * answers.length - min * answers.length)) *
    100
  )
}

const vectorNorm = (a: number[], b: number[]): number => {
  const sum = a.reduce((sum, el, index) => sum + (el - b[index]) ** 2, 0)
  return Math.sqrt(sum)
}

const closestParties = (
  answers: number[],
  partyScores: { [party: string]: number[] },
  max: number
): { party: string, percentage: number }[] => {
  const maxNorm = vectorNorm(
    Array(answers?.length).fill(max),
    Array(answers?.length).fill(0)
  )
  const norms = Object.entries(partyScores)
    .map(([party, score]) => ({ party, dist: vectorNorm(score, answers) }))
    .sort((a, b) => (a.dist > b.dist ? 1 : -1))
    .map(({ party, dist }) => ({
      party,
      percentage: Math.round(((maxNorm - dist) / maxNorm) * 100),
    }))

    


  return norms
}