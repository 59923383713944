/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import { Provider } from "./src/context/context.tsx"

import React from "react"

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
